<template>
  <div class="row align-items-center mb-4">
    <div class="col-8">
      <h4 class="font-weight-bold py-3 mb-0">
        {{ pageHeadTitle || $t('configurationHead.title') }} <span class="text-muted font-weight-light">
          | {{ pageName }}
        </span>
      </h4>
    </div>
    <div class="col-4 d-flex justify-content-end">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfigurationHead',
  props: {
    pageHeadTitle: {
      type: String,
      default: null,
    },
    pageName: {
      type: String,
      default: '',
    },
  },
};
</script>
